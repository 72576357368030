/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    em: "em",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Revolutionizing the Boating Experience with BoatBot"), "\n", React.createElement(_components.p, null, "BoatBot is a revolutionary platform that aims to transform the boating experience for owners, vendors, and brokers. It utilizes AI-powered technology to simplify boat management and service provision."), "\n", React.createElement(_components.h2, null, "Empowering Boat Owners and Service Providers"), "\n", React.createElement(_components.p, null, "BoatBot offers features such as real-time communication, simplified service scheduling, predictive maintenance, and a comprehensive digital service record. The platform empowers boat owners by giving them control over their vessels and enables service providers to optimize their operations and assure customer satisfaction."), "\n", React.createElement(_components.h2, null, "Bridging the Gap in the Marine Service Industry"), "\n", React.createElement(_components.p, null, "BoatBot also bridges the gap between boat owners and their trusted vendors in the marine service industry. It allows vendors to coordinate multiple providers, track the real-time status of repairs and maintenance, and access service histories."), "\n", React.createElement(_components.p, null, "By leveraging smart technology, BoatBot aims to revolutionize boat service and ownership by streamlining processes, enhancing communication, and building trust between all stakeholders."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Reference:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aithority.com/technology/ai-at-the-helm-revolutionizing-boat-service-and-ownership-with-boatbots-smart-technology/"
  }, "AI at the Helm: Revolutionizing Boat Service and Ownership with BoatBot's Smart Technology - AiThority")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://boatbot.ai/"
  }, "BoatBot: Revolutionize Your Boating Experience")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://boatbot.ai/about/"
  }, "About BoatBot: Our Mission & Vision")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.linkedin.com/company/boatbot"
  }, "BoatBot - LinkedIn")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
